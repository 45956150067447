/**
 * @name: 档口管理-收银秤管理接口文件
 * @author: itmobai
 * @date: 2023-08-04 17:46
 * @description： 档口管理-收银秤管理接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IScale, IScaleParam } from "./types";

/**
 * 收银秤列表查询
 * @param params 查询参数
 * @returns
 */
export const cashierScaleQueryApi = (params: IScaleParam) => postJ<IPageRes<IScale[]>>(`/admin/cashierScale/query?page=${params.page}&limit=${params.limit}`, params)
/**
 * 收银秤修改
 * @param data
 * @returns
 */
export const cashierScaleModifyApi = (data: IScale) => postJ("/admin/cashierScale/modify", data)
/**
 * 收银秤创建
 * @param data
 * @returns
 */
export const cashierScaleCreateApi = (data: IScale) => postJ("/admin/cashierScale/create", data)
/**
 * 收银秤详情
 * @param id 收银秤id
 * @returns
 */
export const cashierScaleDetailApi = (id: string) => get<IScale>("/admin/cashierScale/detail/" + id)
/**
 * 修改收银秤价格属性开关
 * @param data
 * @returns
 */
export const cashierScaleUpdateScaleAttrApi = (data: any) => postJ("/admin/cashierScale/updateScaleAttr", data)
